@use "sass:map";
@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

.cdk-overlay-pane {
  @include mq.layout-bp(gt-sm) {
    &.has-limited-width .mat-bottom-sheet-container {
      width: 500px !important;
      max-width: 500px !important;
    }
  }

  .mat-bottom-sheet-container {
    padding: 0 !important;

    @include mq.layout-bp(gt-sm) {
      border-top-left-radius: var.$border-radius !important;
      border-top-right-radius: var.$border-radius !important;
    }

    header {
      padding: var.$dialog-content-padding-y-sm var.$dialog-content-padding-x-sm;
    }

    main {
      background-color: map.get(color.$monochrome, 100);
      padding: var.$dialog-content-padding-y-sm var.$dialog-content-padding-x-sm;
    }
  }
}
